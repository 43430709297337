import type { DeprecatedNewsFeedItem, GeneratedNewsFeedItem } from '../types';

export const isNotDeprecatedNewsFeedItem = <T extends Pick<GeneratedNewsFeedItem, '__typename'>>(
  input: T
): input is Exclude<T, DeprecatedNewsFeedItem> => {
  return true;
  //This is currently always returning true as there are currently no deprecatedNewsFeedItems in the generated type GeneratedNewsFeedItem.
  //This util function is however not deleted as it will most likely be used in the future. See example below.
  //return input.__typename !== 'FrequentlyAskedQuestions' && input.__typename !== 'MostViewedVideos';
};

// export const isNotDeprecatedNewsFeedItemForPlugin = <T extends Pick<GeneratedNewsFeedItemForPlugin, '__typename'>>(
//   input: T
// ): input is Exclude<T, DeprecatedNewsFeedItem> => {
//   return true;
//   //This is currently always returning true as there are currently no GeneratedNewsFeedItemForPlugin in the generated type GeneratedNewsFeedItem.
//   //This util function is however not deleted as it will most likely be used in the future. See example below.
//   //return input.__typename !== 'FrequentlyAskedQuestions' && input.__typename !== 'MostViewedVideos';
// };
