import type {
  ArticleFieldsFragment,
  ArticleInFeedFieldsFragment,
  RelatedArticleFieldsFragment,
  VideoAssetFieldsFragment,
} from '@news/gql';

import type {
  ArticleGroupArticle,
  ArticleGroupBlockArticle,
  BreakingNewsEntry,
  VideoPlayerDataWithAsset,
} from '../../src/types';
import { slugify } from './slugify';

export const getItemUrlForFeedItem = (
  item:
    | ArticleFieldsFragment
    | ArticleInFeedFieldsFragment
    | ArticleGroupArticle
    | ArticleGroupBlockArticle
    | RelatedArticleFieldsFragment
    | VideoPlayerDataWithAsset
    | VideoAssetFieldsFragment
    | BreakingNewsEntry
): string => {
  switch (item.__typename) {
    case 'Article':
    case 'ArticleGroupArticle':
    case 'RelatedArticle':
      return `/artikel/${item.id}/${item.slug}`;
    case 'VideoAsset':
      return `/klipp/va/${item.id}/${item.slug}`;
    case 'VideoPlayerData':
      return `/klipp/va/${item.asset.id}/${item.asset.slug}`;
    case 'BreakingNews': {
      return `/just-nu/${item.id}${item.title ? `/${slugify(item.title)}` : ''}`;
    }
  }
};
